import React from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import { Flex } from 'workspace-core-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import splitSvgGatorHtml from '@utils/splitSvgGatorHtml';
import { Script } from 'gatsby';
import ClientOnly from './ClientOnly';

const sharedImageStyling = {
  position: 'relative',
  maxWidth: 'clamp(100px, 70vw - 1rem, 400px)',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  top: 0,
};

const InlineScript = ({
  svgScriptTagsContent,
}: {
  svgScriptTagsContent: string[] | string;
}) => (
  <>
    {svgScriptTagsContent.map((sContent, index) => (
      <Script
        id={index}
        key={index}
        dangerouslySetInnerHTML={{
          __html: sContent,
        }}
      />
    ))}
  </>
);

const AnimatedIntroImage: React.FC = ({
  imageData,
  imageType,
  overrideImageStyling,
}: {
  imageType: 'gatsbyImage' | 'svg';
  imageData: object;
  // TODO: get right types for this
  overrideImageStyling?: object;
}) => {
  let srcWithoutScript;
  let svgScriptTags;

  if (imageType === 'svg') {
    [srcWithoutScript, svgScriptTags] = splitSvgGatorHtml(imageData.data);
  }

  return (
    <>
      {svgScriptTags && (
        <ClientOnly>
          <InlineScript svgScriptTagsContent={svgScriptTags} />
        </ClientOnly>
      )}
      <LazyMotion features={domAnimation}>
        <m.div
          initial={{
            scale: 0.5,
            alignSelf: 'center',
          }}
          transition={{
            type: 'spring',
            damping: 10,
            stiffness: 500,
            delay: 0.2,
          }}
          animate={{ scale: 1 }}
        >
          {imageType === 'svg' ? (
            <Flex
              dangerouslySetInnerHTML={{
                __html: srcWithoutScript,
              }}
              sx={{
                svg: {
                  width: '100%',
                  height: '100%',
                  maxHeight: 'clamp(100px, 40vh - 1rem, 400px)',
                  ...sharedImageStyling,
                  ...overrideImageStyling,
                },
              }}
            />
          ) : (
            // NOTE, prop errors are due to gatsby image interface not meshing with flex, fix later
            <Flex
              image={imageData}
              sx={{
                ...sharedImageStyling,
                ...overrideImageStyling,
              }}
              as={GatsbyImage}
            />
          )}
        </m.div>
      </LazyMotion>
    </>
  );
};

export default AnimatedIntroImage;
