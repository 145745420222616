import React from 'react';
import { Flex } from 'workspace-core-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const sharedImageStyling = {
  isolation: 'isolate',
  position: 'fixed!important',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  bottom: 0,
  height: '100vh',
  width: 'auto',
  minWidth: '100vw',
};

// Injecting preserveAspectRatio into the SVG source code
// FIXME: this's better done during build time
function makeSVGStretchable(svg) {
  return svg.replace(/ fill=/, 'preserveAspectRatio="xMidYMid slice" $&')
}

const BackgroundImage: React.FC = ({
  imageData,
  imageType,
  overrideImageStyling,
}: {
  imageType: 'gatsbyImage' | 'svg';
  imageData: object;
  // TODO: get right types for this
  overrideImageStyling?: object;
}) => (
  <div aria-hidden="true">
    {imageType === 'svg' && (
      <Flex id="bgimg"
        dangerouslySetInnerHTML={{
          __html: makeSVGStretchable(imageData.data),
        }}
        sx={{
          svg: {
            ...sharedImageStyling,
            ...overrideImageStyling,
          },
        }}
      />
    )}
    {imageType === 'gatsbyImage' && (
      <Flex id="bgimg"
        image={imageData}
        sx={{
          ...sharedImageStyling,
          ...overrideImageStyling,
        }}
        as={GatsbyImage}
      />
    )}
  </div>
);

export default BackgroundImage;
