import React, { useRef, useState } from 'react';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import { motion } from 'framer-motion';
import useTranslation from '@hooks/useTranslation';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Box, Para, Heading } from 'workspace-core-ui';
import gameConfig from '@content/gameconfig';
import SubmitBetterInfoModalButton from './SubmitBetterInfoModalButton';

const ReadMoreWrapper = styled(Para).attrs({
  variant: 'p2',
  as: 'div',
})(
  css({
    textAlign: 'center',
    overflow: 'auto',
    position: 'relative',
    height: 'min(400px, 60vh)',
    top: '20%',
    padding: 'inherit',
    pb: 4,
  }),
);

export interface ReadMoreViewProps {
  sources?: string;
  text: string | undefined;
  questionName: string;
}

const ReadMoreView = ({ text, sources, questionName }: ReadMoreViewProps) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ReadMoreWrapper ref={ref}>
        {text && (
          <Para as="span">
            <CustomMdxRenderer>{text}</CustomMdxRenderer>
          </Para>
        )}
        {sources && (
          <>
            <Heading mb={3} mt={6} fontSize="14px">
              {t('Result Why True Body Header')}
            </Heading>
            <Para as="span" variant="p3">
              <CustomMdxRenderer>{sources}</CustomMdxRenderer>
            </Para>
          </>
        )}
        {gameConfig.Enable_Submit_Better_Info && (
          <SubmitBetterInfoModalButton questionName={questionName} />
        )}
      </ReadMoreWrapper>
    </Box>
  );
};

export default ReadMoreView;
